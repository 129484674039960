import React, { Fragment } from "react"
import Bayout from "../../components/blogLayout"
import BlogListItem from "../../components/blogListItem"
import { graphql } from "gatsby"

export default ({ data, pageContext: { name, slug, numPages, current } }) => {
  const categoryBasePath = `/blog/category/${slug}/`;

  return (
    <Bayout
      hasPage={true}
      numPages={numPages}
      currentPage={current}
      pageBasePath={categoryBasePath}
    >
      <div className="blog-section-title">{name}</div>
      <div className="blog-list d-flex flex-column">
        {data.allWordpressPost.edges.map(({ node }, i) =>
          <Fragment key={node.id}>
            <BlogListItem node={node} />
            {i < data.allWordpressPost.edges.length - 1 && <hr />}
          </Fragment>
        )}
      </div>
    </Bayout>
  )
};

export const query = graphql`
  query($slug: String!, $limit:Int!, $skip:Int!) {
    allWordpressPost(sort: {fields: [date], order: [DESC]}, limit: $limit, filter: {categories: {elemMatch: {slug: {eq: $slug}}}}, skip: $skip){
      edges {
        node {
          title
          excerpt
          slug
          path
          id
          date(formatString: "YYYY/MM/DD")
          categories {
            name
            path
          }
          featured_media {
            source_url
         }
        }
      }
    }
  }
`
